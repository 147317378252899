import Vue from 'vue'
import VueRouter from 'vue-router'

import ll from './lazyLoading.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: ll('home')
  },
  {
    path: '/photos',
    name: 'Photos',
    component: ll('photos')
  },
  // {
  //   path: '/dress',
  //   name: 'Dress',
  //   component: ll('dress')
  // },
  {
    path: '/:id',
    name: 'IdHandler',
    component: ll('_id')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
