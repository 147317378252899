<template lang="pug">
  .v-counter(:class="classes")
    button.v-counter__btn(@click="dec")
      img.v-counter__icon(src="@/assets/svg/minus.svg")
    .v-counter__value {{ count }}
    button.v-counter__btn(@click="inc")
      img.v-counter__icon(src="@/assets/svg/plus.svg")
</template>

<script>
export default {
  name: 'VCounter',

  props: {
    value: {
      type: Number,
      default: 0
    },
    light: Boolean
  },

  mounted () {
    this.count = this.value
  },

  data: () => ({
    count: 0
  }),

  computed: {
    classes () {
      return {
        'v-counter--light': this.light
      }
    }
  },

  methods: {
    dec () {
      if (this.count > 0) {
        this.count--
        this.$emit('change', this.count)
      }
    },

    inc () {
      if (this.count < 15) {
        this.count++
        this.$emit('change', this.count)
      }
    }
  }
}
</script>

<style lang="scss">
  .v-counter {
    color: #1D1D1D;
    padding: .8rem;
    border-radius: 1rem;
    border: .1rem solid #DCAFAC;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 19rem;

    &__btn {
      padding: 0;
      height: 2.4rem;
      width: 2.4rem;
      border-radius: 50%;
      background-color: #F7EFEB;
      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        background-color: #DCAFAC;
      }
    }

    &__value {
      font-size: 1.4rem;
    }

    &__icon {
      width: 1rem;
    }

    &--light {
      border: none;
    }

    &--light &__value {
      color: #F7EFEB;
    }
  }
</style>
