<template lang="pug">
  #app(
    v-if="isAppLoaded"
    v-show="isInvitationLoaded")
    desktop-wrapper
      template(slot="noscroll")
        app-menu
        intro-screen(
          v-if="showIntro && hasInvitation"
          @intro="showIntro = false")
      app-header(v-if="hasInvitation")
      router-view
</template>

<script>
import { preloadImage } from '@/util/index.js'

import AppMenu from '@/components/common/AppMenu'
import AppHeader from '@/components/common/AppHeader'
import IntroScreen from '@/components/common/IntroScreen'
import DesktopWrapper from '@/components/common/DesktopWrapper'

export default {
  name: 'App',

  components: {
    AppMenu,
    AppHeader,
    IntroScreen,
    DesktopWrapper
  },

  computed: {
    isInvitationLoaded () {
      return this.$store.getters['invitation/loaded']
    },

    hasInvitation () {
      return !!this.$store.getters['invitation/invitation']
    },

    isDemo () {
      return this.$store.getters.isDemoMode
    }
  },

  data: () => ({
    showIntro: true,
    isAppLoaded: false
  }),

  beforeMount () {
    this.$store.dispatch('invitation/init')
  },

  async mounted () {
    const intro = require('@/assets/img/splash-screen.jpg')
    await preloadImage(intro)
    this.isAppLoaded = true
    this.preloadResources()
  },

  beforeDestroy () {
    this.$store.dispatch('invitation/unsubscribe')
  },

  methods: {
    async preloadResources () {
      await Promise.all([
        preloadImage(require('@/assets/img/main.jpg')),
        preloadImage(require('@/assets/svg/caption.svg')),
        preloadImage(require('@/assets/svg/revolut.svg')),
        preloadImage(require('@/assets/svg/davidjones.svg')),
        preloadImage(require('@/assets/svg/invito.svg')),
        preloadImage(require('@/assets/img/photos-bg.jpg')),
        preloadImage(require('@/assets/img/second.jpg'))
      ])
    }
  }
}
</script>

<style lang="scss">
  @import '@/sass/main.scss';

  #app {
    position: relative;

    .app-inner {
      position: relative;
      max-width: 80rem;
      margin: 0 auto;
    }
  }
</style>
