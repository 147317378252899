import { initializeApp } from 'firebase/app'
import { doc, setDoc, getFirestore, onSnapshot } from 'firebase/firestore'

const MOCK_INVITATION = {
  texts: {
    caption: null,
    you: null
  },
  guests: [
    {
      name: 'Oleksandr',
      dietary: null,
      rsvp: {
        answer: null,
        banquet: null,
        ceremony: null
      }
    },
    {
      name: 'Lina',
      dietary: null,
      rsvp: {
        answer: null,
        banquet: null,
        ceremony: null
      }
    }
  ],
  lang: 'en'
}

const firebaseConfig = {
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID
}

initializeApp(firebaseConfig)

const db = getFirestore()
const INVITATIONS = 'invitations'
const INV_KEY = 'inv_id'

const SET_INVITATION = 'SET_INVITATION'
const SET_INVITATION_ID = 'SET_INVITATION_ID'
const SET_UNSUBSCRIBE = 'SET_UNSUBSCRIBE'
const SET_LOADED = 'SET_LOADED'

export default {
  namespaced: true,

  state: {
    loaded: false,
    invitation: null,
    unsubscribe: null,
    invitationId: localStorage.getItem(INV_KEY) || null
  },

  getters: {
    loaded: (state) => state.loaded,
    invitation: (state) => state.invitation
  },

  mutations: {
    [SET_INVITATION_ID] (state, data) {
      state.invitationId = data
    },

    [SET_INVITATION] (state, data) {
      state.invitation = data
    },

    [SET_UNSUBSCRIBE] (state, data) {
      state.unsubscribe = data
    },

    [SET_LOADED] (state, data) {
      state.loaded = data
    }
  },

  actions: {
    setInvitationId ({ commit, dispatch }, data) {
      commit(SET_INVITATION_ID, data)
      localStorage.setItem(INV_KEY, data)
      return dispatch('init', data)
    },

    init ({ state, commit }, id) {
      if (process.env.VUE_APP_MODE !== 'demo') {
        if (id || state.invitationId) {
          const unsubscribe = onSnapshot(doc(db, INVITATIONS, id || state.invitationId), (doc) => {
            const data = doc.data()
            if (data) {
              console.log(data)
              if (data.group === 'evIScz3CfjsDJ2YJ8bG4') {
                commit(SET_INVITATION, data)
              } else {
                commit(SET_INVITATION, null)
              }
            } else {
              commit(SET_INVITATION_ID, null)
            }
            commit(SET_LOADED, true)
          })
          commit(SET_UNSUBSCRIBE, unsubscribe)
        } else {
          commit(SET_LOADED, true)
        }
      } else {
        commit(SET_INVITATION, MOCK_INVITATION)
        commit(SET_LOADED, true)
      }
    },

    update ({ state }, data) {
      if (process.env.VUE_APP_MODE !== 'demo') {
        if (state.invitationId) {
          setDoc(doc(db, INVITATIONS, state.invitationId), { ...data }, { merge: true })
        }
      }
    },

    unsubscribe ({ state, commit }) {
      if (state.unsubscribe) {
        state.unsubscribe()
        commit(SET_UNSUBSCRIBE, null)
      }
    }
  }
}
